<template>
  <div>
    <div class="content">
        <div class="SearchBar">
          <el-form :inline="true" class="searchBoxForm">
            <el-form-item label="用户分类" class="searchItem">
              <el-select
                  v-model="pageParams.userType"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  filterable
                  
              >
              <!-- @change="search" -->
                <el-option
                    v-for="(item, index) in userTypeList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检员" class="searchItem">
              <el-select
                  clearable
                  filterable
                  v-model="pageParams.userId"
                  placeholder="请选择"
                  size="mini"
                  
              >
                <el-option
                    v-for="(item, index) in peopleList"
                    :key="index"
                    :label="item.userName"
                    :value="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" class="searchItem">
              <el-select
                  
                  clearable
                  filterable
                  v-model="pageParams.finishType"
                  placeholder="请选择"
                  size="mini"
              >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务编号" class="searchItem">
              <el-input
                  size="mini"
                  v-model="pageParams.taskCode"
                  placeholder="请输入"
                  clearable
                  class="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="search" size="mini" >查询</el-button>
              <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
              <el-button type="success" @click="exportFile" size="mini" v-if="$anthButtons.getButtonAuth('daoofangbuyudaochu')">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
      <el-table
              ref="tableData"
              :data="tableData"
              style="width: 100%;"
              border
              :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
              height="calc(100vh - 390px)"
              :stripe="true"
          >
            <el-table-column
                type="index"
                :index="indexMethod"
                label="序号"
                width="60"
            >
            </el-table-column>
            <el-table-column
                prop="taskCode"
                width="180"
                label="任务编号"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="userType"
                label="用户分类"
                width="180"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="orderCode" label="安检员"   width="150">
              <template slot-scope="{row}">
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.userName">
                  <div  slot="reference" class="twoLines">
                    {{row.userName}}
                  </div>
                </el-popover> -->
                <el-tooltip class="item" effect="dark" :content="row.userName" placement="top-start">
                  <div class="twoLines">{{row.userName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                prop="taskTime"
                label="任务时间"
                width="180"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="finishType" label="状态" >
              <!--            <template slot-scope="{row}">-->
              <!--                 <el-tag v-if="row.finishType==2" type="success">进行中</el-tag>-->
              <!--                <el-tag v-if="row.finishType==3" type="danger">已结束</el-tag>-->
              <!--            </template>-->
            </el-table-column>
            <el-table-column
                prop="houseCount"
                label="总户数"
                width="80"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="completeCount"
                label="已检查数"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="visitWorst"
                label="到访不遇总数"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="doneCount"
                label="已完成数"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="undone"
                label="未完成数"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="penetration"
                label="到访不遇率"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="left" width="120">
              <template slot-scope="{row}">
                <div class="operateTool">
                  <el-button type="primary" @click="goPath('/home/wkUnresident',row.taskCode)" size="mini" v-if="$anthButtons.getButtonAuth('dfbyckgd')">查看工单</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        <div class="total">
          <el-pagination
              background
              layout="total, prev, pager, next,sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageParams.current"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="pageParams.size"
          >
          </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import {userTypeDropDown} from "@/RequestPort/induction";
import {
  safetyInspectorDropDown} from "@/RequestPort/staticisc";
import pageMixins from "@/utils/pageMixins";
import { exportMethod } from '../../common/js/exportExcel'
import {visitWorstTask, visitWorstTaskExcel} from "../../RequestPort/staticisc";
export default {
  mixins: [pageMixins],
  data() {
    return {
      userTypeList:[],
      peopleList:[],
      statusList:[
        {id:2,name:'进行中'},{id:3,name:'已结束'}
      ],
      pageParams: {
        userType:'',
        userId:'',
        taskCode:'',
        finishType: "",
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:null,
      tableData: [{}], //  表格数据
      title:''
    };
  },
  mounted() {
    userTypeDropDown().then(res=>{
      this.userTypeList = res.data
    })
    safetyInspectorDropDown().then(res=>{
      this.peopleList = res.data
    })
    this.loadList(this.pageParams)
  },
  methods: {
    exportFile(){
      visitWorstTaskExcel(this.pageParams).then(res=>{
        exportMethod(res,'到访不遇报表—任务维度')
      })
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        userType:'',
        userId:'',
        taskCode:'',
        finishType: "",
        current: 1,
        size: 100,
      };
      this.loadList(this.pageParams);
    },
    loadList(obj){
      visitWorstTask(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { taskCode: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  background-color: #ffffff;
}
.content {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 250px);
}
.search{
  width: 190px;
}
.contain{
  height: 595px;
}
</style>